<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <h1>
              Attestation Fiscale pour l'année
              {{ contactFiscale.year }}
            </h1>
          </div>
          <div class="modal-body">
            <div class="d-flex flex-column w-100" id="">
              <div class="flex-grow-1 overflow-hidden d-flex">
                <div class="card card-no-margin w-100">
                  <div class="row">
                    <div v-if="dialogNoRequest" :class="successOrDanger">
                      {{ dialogTxtNoRequest }}
                    </div>
                  </div>
                  <h5>
                    <span v-if="loadingStripe">
                      Récupération des données de l'attestation. Veuillez patienter svp!
                      <icon width="30" height="30" name="sync" class="fa-spin"></icon
                      ></span>
                  </h5>
                  <div class="row">
                    <div class="col-4">
                      <m-form-select
                        label="Année: "
                        v-model="contactFiscale.year"
                        :name="$Utils.randomstring('year')"
                        :rules="[$Validation.mandatory]"
                        :items="itemsYear"
                        @input="changeYear"
                      ></m-form-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <m-form-text
                        label="Utilisateur Final: "
                        type="text"
                        v-model="row_en.en_fullname"
                        :name="$Utils.randomstring('date')"
                        :rules="[$Validation.mandatory]"
                        :readonly="true"
                      ></m-form-text>
                    </div>
                    <div class="col-4">
                      <m-form-text
                        label="Date de début de l'abonnement: "
                        type="text"
                        v-model="dateAbonnement"
                        :name="$Utils.randomstring('date')"
                        :rules="[$Validation.mandatory]"
                        :readonly="true"
                      ></m-form-text>
                    </div>
                    <div class="col-4">
                      <m-form-text
                        label="Montant dû pour l'année fiscale"
                        type="text"
                        v-model="amountToPaidThisYear"
                        :name="$Utils.randomstring('AmountToBePaid')"
                        :rules="[$Validation.mandatory]"
                        :readonly="true"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <m-form-select
                        label="Civilité"
                        v-model="contactFiscale.civility"
                        :name="$Utils.randomstring('us_civility')"
                        :rules="[$Validation.mandatory]"
                        :items="$store.state.items_civility"
                      ></m-form-select>
                    </div>
                    <div class="col-9">
                      <m-form-text
                        label="Prénom & Nom à faire figurer sur l’attestation : "
                        type="text"
                        v-model="contactFiscale.name"
                        :name="$Utils.randomstring('name')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <m-form-text
                        label="Prix mensuel de l’abonnement : "
                        type="number"
                        v-model="contactFiscale.price"
                        :name="$Utils.randomstring('price')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                    <div class="col-6">
                      <m-form-text
                        label="Montant total figurant sur l'attestation : "
                        type="number"
                        v-model="contactFiscale.totalAmount"
                        :name="$Utils.randomstring('totalAmount')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                  </div>
                  <m-form-text
                    label="Adresse postale figurant sur l'attestation: "
                    type="textarea"
                    v-model="contactFiscale.address"
                    :name="$Utils.randomstring('address')"
                    :rows="2"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                  <div class="row">
                    <div class="col-6">
                      <button type="button" class="btn btn-primary btn-mouveal" @click="generatePdfTax">
                        <icon width="20" height="20" name="file-pdf" class="" color="#91295a"></icon>Générer l'attestation
                      </button>
                    </div>
                  </div>

                  <div class="row d-flex align-items-center">
                    <div class="col-4">
                      <m-form-text
                        label="Adresse mail d’envoi de l'attestation: "
                        type="text"
                        v-model="contactFiscale.email"
                        :name="$Utils.randomstring('email')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                    <div class="col-4">
                      <button type="button" class="btn btn-primary btn-mouveal mt-2" @click="sendAttestation">Envoyer l'attestation par mail</button>
                    </div>
                    <div class="col-4">
                      <button type="button" class="btn btn-danger btn-mouveal mt-2" @click="sendIrrecevableMail">
                        Envoyer un mail d'irrecevabilité
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <m-message-dialog v-model="dialog" :title="title" :text="dialogTxt"></m-message-dialog>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row">
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary mr-2" @click="cancelWin">Annuler</button>
            </div>
          </div>
        </div>
      </div>
      <previsualize-and-send-fiscal-attestation
        v-model="previzualizeAndSend"
        @winEditCanceled="previzualizeAndSend = false"
        :contactFiscale="contactFiscale"
      ></previsualize-and-send-fiscal-attestation>
    </div>
  </transition>
</template>
<script>
import PrevisualizeAndSendFiscalAttestation from "./PrevisualizeAndSendFiscalAttestation";
const initialData = () => {
  return {
    contactFiscale: {
      co_id: "",
      name: "",
      price: 30,
      totalAmount: "",
      address: "",
      email: "",
      users: [],
      civility: "M",
      selectedUser: "",
      AmountToBePaid: ""
    },
    loadingStripe: true,
    dialog: false,
    dialogTxt: "",
    dialogTxtNoRequest: "",
    dialogNoRequest: false,
    title: "Erreur",
    allSubscriptions: [],
    activeSubscription: null,
    successOrDanger: "alert alert-success",
    previzualizeAndSend: false
  };
};
export default {
  name: "AttestationFiscale",
  components: { PrevisualizeAndSendFiscalAttestation },
  props: {
    value: {
      default: false,
      type: Boolean
    },
    row_fisc: {
      default: null,
      type: Object
    },
    row_en: {
      type: Object
    }
  },
  data() {
    return {
      contactFiscale: {
        co_id: "",
        name: "",
        price: 30,
        totalAmount: "",
        address: "",
        email: "",
        users: [],
        civility: "M",
        selectedUser: "",
        AmountToBePaid: "",
        year: this.$moment().subtract(1, "year").format("YYYY")
      },
      loadingStripe: true,
      dialog: false,
      dialogTxt: "",
      dialogTxtNoRequest: "",
      dialogNoRequest: false,
      title: "Erreur",
      allSubscriptions: [],
      activeSubscription: null,
      successOrDanger: "alert alert-success",
      previzualizeAndSend: false,
      itemsYear : [
        {value: this.$moment().format("YYYY"), text: this.$moment().format("YYYY")},
        {value: this.$moment().subtract(1, "year").format("YYYY"), text: this.$moment().subtract(1, "year").format("YYYY")},
        {value: this.$moment().subtract(2, "year").format("YYYY"), text: this.$moment().subtract(2, "year").format("YYYY")}
      ]
    };
    //return initialData();
  },
  watch: {
    /*async row_en(v) {
      await this.loadPayments();
      this.loadDataAttestation();
    },*/
    async value(v) {
      await this.loadPayments();
      this.loadDataAttestation();
    }
  },
  async mounted() {
    initialData();
    //if (this.row_fisc == null) this.loadDataAttestation();
    //else this.fillContactFiscale(this.row_fisc);
  },
  computed: {
    dateAbonnement() {
      return this.activeSubscription ? this.$moment(this.activeSubscription.su_startdate).format("DD/MM/YYYY") : "";
    },
    amountToPaidThisYear() {
      let startDateSubscription = null;
      if (this.activeSubscription) startDateSubscription = this.activeSubscription.su_startdate;
      else startDateSubscription = "";
      var lastDayOfLastYear = this.$moment({ year: this.contactFiscale.year, month: 1, day: 1}).endOf("year");
      if (this.$moment(startDateSubscription).isBefore(lastDayOfLastYear)) {
        let nMonths = this.$moment(lastDayOfLastYear).diff(this.$moment(startDateSubscription), "months") + 1;

        if (nMonths >= 12) {
          return 30 * 12;
        } else {
          return 30 * nMonths;
        }
      } else {
        return 0;
      }
    }
  },
  methods: {
    cancelWin() {
      Object.assign(this.$data, initialData());
      this.$emit("winRequestAttestationCanceled");
    },
    async loadDataAttestation() {
      if (!this.row_en || !this.row_en.en_id) return;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/attestationFiscale", {
        params: {
          en_id: this.row_en.en_id,
          year: this.contactFiscale.year
        }
      });
      if (response.data.data) {
        this.fillContactFiscale(response.data.data);
      } else {
        this.dialogTxtNoRequest = "Aucune demande d'attestation fiscale n'a été effectuée pour cet utilisateur.";
        this.contactFiscale.totalAmount = response.data.totalAmount / 100;

        this.loadingStripe = false;
        this.dialogNoRequest = true;
      }
    },
    async changeYear(){
      this.loadingStripe = true;
      await this.loadPayments();
      await this.loadDataAttestation();
      this.loadingStripe = false;
    },
    fillContactFiscale(data) {
      if(!this.contactFiscale.year) this.contactFiscale.year = data.fisc_year;
      this.contactFiscale.civility = data.fisc_civility;
      this.contactFiscale.name = data.fisc_fullname;
      this.contactFiscale.totalAmount = data.totalAmount / 100;
      this.contactFiscale.address = data.fisc_address;
      this.contactFiscale.email = data.fisc_email;
      this.loadingStripe = false;
      if (data.fisc_status == 1) {
        this.dialogTxtNoRequest = "Une attestation fiscale pour cet utilisateur a déjà été envoyée par mail.";
        this.dialogNoRequest = true;
      } else if (data.fisc_status == 2) {
        this.dialogTxtNoRequest = "Un mail d'irrecevabilité de la demande d'attestation a déjà été envoyé par mail pour cet utilisateur.";
        this.successOrDanger = "alert alert-danger";
        this.dialogNoRequest = true;
      }
    },
    async generatePdfTax() {
      let err = [];
      let fieldRequired = [
        { field: "name", text: "Prénom & Nom à faire figurer sur l’attestation" },
        { field: "price", text: "Prix de l'abonnement" },
        { field: "address", text: "Adresse du contact" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.contactFiscale[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogTxt += error.text + " <br>";
        }
        this.dialog = true;
      } else {
        window.open(
          this.$config.server_url +
          "/extranet/2.0/contact/pdfTax?d=" +
          new Date().getTime() +
          "&year=" +
          this.contactFiscale.year +
          "&civility=" +
          this.contactFiscale.civility +
          "&name=" +
          this.contactFiscale.name +
          "&price=" +
          this.contactFiscale.price +
          "&totalAmount=" +
          this.contactFiscale.totalAmount +
          "&address=" +
          this.contactFiscale.address +
          "&token=" +
          this.$store.state.accesstoken,
          "_blank"
        );
      }
    },
    async sendAttestation() {
      let err = [];
      let fieldRequired = [
        { field: "name", text: "Prénom & Nom à faire figurer sur l’attestation" },
        { field: "price", text: "Prix de l'abonnement" },
        { field: "address", text: "Adresse du contact" },
        { field: "email", text: "Adresse mail d’envoi de l'attestation" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.contactFiscale[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogTxt += error.text + " <br>";
        }
        this.dialog = true;
      } else {
        this.contactFiscale.selectedUser = this.row_en.en_id;
        this.previzualizeAndSend = true;
        // let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/contact/sendTaxPdf", this.contactFiscale);
        // if (response.data.data === "success") {
        //   this.title = "Succès";
        //   this.dialogTxt = "L'attestation a été envoyée avec succès.";
        //   this.dialog = true;
        //   // timer
        //   setTimeout(() => {
        //     this.dialog = false;
        //     this.$emit("winRequestAttestationCanceled");
        //   }, 5000);
        // } else {
        //   this.title = "Erreur";
        //   this.dialogTxt = "Une erreur est survenue lors de l'envoi de l'attestation.";
        //   this.dialog = true;
        // }
      }
    },
    async sendIrrecevableMail() {
      let err = [];
      let fieldRequired = [{ field: "email", text: "Adresse mail d’envoi de l'attestation" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.contactFiscale[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogTxt += error.text + " <br>";
        }
        this.dialog = true;
      } else {
        this.contactFiscale.selectedUser = this.row_en.en_id;
        let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/irrecevable", this.contactFiscale);
        if (response.data.data === "success") {
          this.title = "Succès";
          this.dialogTxt = "Le mail a été envoyé avec succès.";
          this.dialog = true;
          // timer
          setTimeout(() => {
            this.dialog = false;
            this.$emit("winRequestAttestationCanceled");
          }, 5000);
        } else {
          this.title = "Erreur";
          this.dialogTxt = "Une erreur est survenue lors de l'envoi du mail.";
          this.dialog = true;
        }
      }
    },

    async loadPayments() {
      if (!this.row_en || !this.row_en.en_id) return;
      this.allSubscriptions = [];

      /** get all payments and subscriptions data from API */
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/payments/" + this.row_en.en_id);
      if (response.data.err) {
        console.error("loadPayments error > ", response.data.err);
        return;
      }

      /** get subscriptions and active subscription */
      if (response.data && response.data.data && response.data.data.subscriptions) {
        this.allSubscriptions = response.data.data.subscriptions;
        this.activeSubscription = this.allSubscriptions.find(subscription => {
          return subscription.su_state === 2;
        });
      } else {
        this.allSubscriptions = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
</style>

